<template>
  <Dialog
    v-if="true"
    :name="name"
    width="526px"
    class-own="dialog-delete-timeslot"
    @close="step = 1"
  >
    <template #title>
      <div style="font-size: 16px; font-weight: 400">
        {{ step === 1 ? 'Удалить тайм слот' : 'Подтвердить удаление' }}
      </div>
    </template>

    <div v-if="step === 1" class="admin-delete-timeslot__message">
      Средства за удаленные тайм слоты возвращаются только из-за технических
      проблем на терминале или квота выставлена ошибочно и только тем, кто еще
      по факту не выгрузился. <br /><br />
      <b>Вернуть средства за удаляемые ТС бронирующим?</b>
    </div>

    <div v-else class="admin-delete-timeslot__message">
      Удалив ТС, вам необходимо самостоятельно сократить квоту. Средства за
      удаленные ТС <b>{{ refund ? '' : 'не' }} вернутся</b> на баланс
      бронирующим. Удаление ТС - процесс необратимый. При необходимости вы
      можете снова увеличить распределенную квоту.<br /><br />
      <b>Вы действительно хотите удалить ТС?</b>
    </div>

    <div class="df jc-sb">
      <iq-mobile-button
        button-style="padding: 12px; width: 100%; margin-right: 4px; background: #F56C6C; border-color: #F56C6C; color: white; cursor: pointer;"
        text-style="color: white; font-weight: 500"
        :title="step === 1 ? 'С возвратом' : 'Нет'"
        @onClick="
          step === 1
            ? handleRefundTimeslots(true)
            : handleRemoveTimeslots(false)
        "
      />
      <iq-mobile-button
        button-style="padding: 12px; width: 100%; margin-left: 4px; background: #F56C6C; border-color: #F56C6C; color: white; cursor: pointer;"
        text-style="color: white; font-weight: 500"
        :title="step === 1 ? 'Без возврата' : 'Да'"
        @onClick="
          step === 1
            ? handleRefundTimeslots(false)
            : handleRemoveTimeslots(true)
        "
      />
    </div>
  </Dialog>
</template>

<script>
import { ADMIN_DELETE_TIMESLOT } from '@/constants/dialogs'
import {
  DELETE_TIMESLOT,
  GET_IS_LOADING_POST_TIMESLOTS,
} from '@/views/manager/store/actions'
import { mapActions, mapGetters } from 'vuex'
import Dialog from '@/UI/dialog/Dialog'
import IqMobileButton from '@/UI/form/mobileButton/IqMobileButton'

export default {
  name: 'DialogAdminDeleteTimeslot',
  components: { IqMobileButton, Dialog },
  data() {
    return {
      name: ADMIN_DELETE_TIMESLOT,
      refund: false,
      step: 1,
    }
  },
  computed: {
    ...mapGetters({ isLoading: GET_IS_LOADING_POST_TIMESLOTS }),
    dialogData() {
      return this.getDialog(this.name)?.data
    },
  },
  methods: {
    ...mapActions({ deleteTimeslot: DELETE_TIMESLOT }),
    handleRefundTimeslots(refund) {
      this.refund = refund
      this.step = 2
    },

    handleRemoveTimeslots(isRemove = false) {
      if (isRemove) {
        const requestData = {
          timeslot_ids: this.dialogData,
          refund: this.refund,
          nameDialog: this.name,
        }

        this.deleteTimeslot(requestData)
      }
      this.setDialog({ name: this.name })
      this.step = 1
    },
  },
}
</script>

<style lang="sass">
.admin-delete-timeslot__message
    color: #303133
    margin-top: 0
    margin-bottom: 24px
    font-size: 16px
.el-button + .el-button
  margin-left: 35px
</style>
